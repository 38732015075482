.site {
	display: flex;
	min-height: calc( 100vh - 3.25rem );
	flex-direction: column;
}

.main-content {
	flex: 1;
}

.title-section {
	position: relative;
	padding-bottom: 0;
	margin-bottom: 0;
}

.time-period-buttons {
	position: absolute;
	top: 3rem;
	right: 1.5rem;
}

.has-interaction {
	cursor: pointer;
}

.has-stacked-panels {

}

.has-stacked-panels .panel {
	margin-bottom: 0;
}

.has-stacked-panels .panel .panel-heading {
	border-radius: 0;
}

.has-stacked-panels .panel.is-active .panel-heading {
	border-left-color: #3273dc;
	color: #363636;
}

.has-stacked-panels .panel:nth-child(odd) .panel-heading {
	 background-color: #fff;
}

.split-container {
	margin-bottom: 0.75rem;
}

.split-container .columns:not(:last-child) {
	margin-bottom: 0;
}

.link-button {
	background-color: transparent;
	border: none;
	cursor: pointer;
	text-decoration: underline;
	display: inline;
	margin: 0;
	padding: 0;
}

.link-button:hover,
.link-button:focus {
	text-decoration: none;
}
